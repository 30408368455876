<template>
	<section id="advices">
		<!-- Advices Table Card -->
		<b-card no-body>
			<!-- Table Top -->
			<b-row>
				<b-col cols="12" md="12">
					<div class="d-flex align-items-center justify-content-end mt-2 mr-2">
						<b-button
							variant="primary"
							@click="
								active = !active;
								data.selectedAdvice = {};
							"
						>
							<feather-icon icon="PlusIcon" class="mr-25" />
							<span class="text-nowrap">Add advice</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
			<advices-table
				@editAdvice="editAdvice"
				@deleteAdvice="deleteAdvice"
				@reload="loadAdvices"
				:table-data="data.advices"
				class="mt-2 mt-md-2"
			/>
		</b-card>
		<!--/ Advices Table Card -->

		<!-- SIDEBAR -->
		<b-sidebar
			width="40%"
			right
			shadow
			parent="body"
			default-index="1"
			spacer
			v-model="active"
			title="Advice settings"
			class="sidebar"
		>
			<div class="px-3 py-2">
				<validation-observer ref="simpleRules">
					<b-form @submit.prevent>
						<b-row>
							<b-col md="12">
								<b-form-group
									label="Name"
									label-for="inputName"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Advice name"
										rules="required"
									>
										<b-form-input
											id="inputName"
											:state="errors.length > 0 ? false : null"
											placeholder="Advice name"
											v-model="data.selectedAdvice.name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group
									label="Advice"
									label-for="textarea-description"
									label-cols-md="12"
								>
									<validation-provider
										#default="{ errors }"
										name="Advice text"
										rules="required"
									>
										<b-form-textarea
											id="textarea-description"
											placeholder="Advice text"
											rows="3"
											v-model="data.selectedAdvice.description"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- save -->
							<b-col md="12">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									type="submit"
									variant="primary"
									class="mr-1"
									@click.prevent="validationForm"
								>
									Save
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>
			</div>
		</b-sidebar>
		<!-- /SIDEBAR -->
	</section>
</template>



<script>
import {
	BCard,
	BCardText,
	BRow,
	BCol,
	BForm,
	BFormGroup,
	BFormInput,
	BFormTextarea,
	BButton,
	BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AdvicesTable from "./AdvicesTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required } from "@validations";

export default {
	data() {
		return {
			active: false,
			data: {
				selectedAdvice: {},
				advices: [],
			},
		};
	},
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		BButton,
		vSelect,
		Cleave,
		AdvicesTable,
		BSidebar,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	methods: {
		async validationForm() {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.saveAdvice();
				}
			});
		},
		async saveAdvice() {
			var url = "/advices";
			var method = "post";

			if (this.data.selectedAdvice.id) {
				url = "/advices/" + this.data.selectedAdvice.id;
				method = "put";
			}

			const res = await this.callApi(method, url, this.data.selectedAdvice);

			this.active = false;
			this.loadAdvices();
		},
		editAdvice(advice) {
			this.data.selectedAdvice = { ...advice };
			this.active = true;
		},
		async deleteAdvice(advice) {
			if (!confirm("Are you sure you want to delete this advice?")) return;
			const res = await this.callApi("delete", "/advices/" + advice.id);
			this.loadAdvices();
		},
		async loadAdvices() {
			const res = await this.callApi("get", "/advices");
			this.data.advices = res.data.result;
		},
		onContext(ctx) {
			// The date formatted in the locale, or the `label-no - date - selected` string
			this.formatted = ctx.selectedFormatted;
			// The following will be an empty string until a valid date is entered
			this.dobSelected = ctx.selectedYMD;
		},
	},
	mounted() {
		this.loadAdvices();
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
	min-height: 2.45rem;
}
</style>